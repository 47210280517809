/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.6.0/dist/jquery.min.js
 * - /npm/locomotive-scroll@4.1.3/dist/locomotive-scroll.min.js
 * - /npm/bootstrap@5.1.3/dist/js/bootstrap.min.js
 * - /npm/jquery-validation@1.19.3/dist/jquery.validate.min.js
 * - /npm/swiper@7.4.1/swiper-bundle.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
